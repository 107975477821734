
.main {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    transition: 0.3s ease-in-out;
    background-image: url("../../assets/13-ripple.png");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
.mockupContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    transition: 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}
.slide {
    top: 0;
    left: calc(100vw - 50%);
}
.imageContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: auto;
    height: 70%
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}

.autumn {
    background: rgba(237, 151, 23, 0.8);
}
.spring {
    background:rgba(81, 255, 0, 0.5);
}
.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 50vw;
    align-items: flex-start;
    background-color: #FFFFFF;
    border-radius: 0 30px 30px 0;
    box-sizing: border-box;
    padding: 5% 2% 1% 2%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -12px -12px 28px rgba(143, 142, 142, 0.12)
}
.title {
    font-size: 48px;
    font-weight: 700;
    margin: 0;
    font-family: Montserrat;
}
.linear {
    background: linear-gradient(90deg, #0000FF 0%, #FF0000 85.5%);
    background-clip: text;
    webkit-background-clip: text;
    box-decoration-break: clone;
    -webkit-text-fill-color: transparent
}
.buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%
}
.buttonTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px
}
.paragraph {
    width: 500px;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: 700;
    color: #737373
}
.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.credentials {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 200
}

.menImg {
    position: absolute;
    bottom: 0;
    right: calc(100% - 90%);
    z-index: 10;
    width: 30%;
}

/*Desktop: 1440px*/
@media screen and (max-width: 1440px) {
    .container {
        padding: 50px;
        width: 55vw;
    }
    .title {
        font-size: 48px;
    }
    .springSlide {
        top: 0;
        left: calc(100vw - 55%);
    }
    .imageContainer {
        justify-content: flex-end;
    }
}

/*Laptop: 1024px*/
@media screen and (max-width: 1024px) {
    .image {
        height: 50%;
        width: auto;
    }
    .container {
        width: 60%;
    }
    .title {
        font-size: 36px;
    }
    .buttonTextContainer {
        width: 100%;
    }
    .paragraph {
        width: 100%;
        font-size: 13px;
        font-family: Montserrat;
        font-weight: 700;
        color: #737373
    }
}

/*MD: 900px*/

@media screen and (max-width: 900px) {
    .mockupContainer {
        display: none;
    }
    .container {
        width: 100%;
        border-radius: 0;
        align-items: center;
        padding: 100px;
    }
    .title {
         text-align: center;
     }
}

/*Tablet: 768px*/
@media screen and (max-width: 768px) {
    .title {
        font-size: 28px;
    }
    .container {
        width: 100%;
        border-radius: 0;
        align-items: center;
        padding: 30px;
    }
    .buttonsContainer {
        padding: 20px 80px;
        box-sizing: border-box;
    }
}

/*Mobile: 425px*/
@media screen and (max-width: 425px) {
    .buttonsContainer {
        padding: 20px;
    }
    .main{
        overflow-x: scroll;
    }
}

/*Small Mobile: 375px*/

@media screen and (max-width: 375px) {

}

@media screen and (max-height: 600px) {
    .logoContainer {
        display: none;

    }
    .container {
        gap: 10px
    }
}