.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    overFlow: hidden
}

.small {
    width: 450px
}
.medium {
    width: 700px
}
.cover {
    width: 90%
}
.laptop {
    paddingLeft: 240px;
    width: 90%
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    marginLeft: auto;
    marginRight: auto;
    animation: apparition 800ms ease-in-out;
    overflowX: hidden;
    maxHeight: 98vh;
    overFlowY: scroll;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
}

@keyframes apparition {
0% {
        top: 200vh
    }
80% {
         top: 40%
     }
100%{
          top: 50%
      }
}

.closeTextContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}
.closeTextButton {
    margin: 0;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: 700;
    color: #737373;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.blockText {
    margin: 20px;

}

.blockText, p {
    margin: 20px;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: 700;
    color: #737373
}
.link {
    font-size: 15px;
    font-family: Montserrat;
    font-weight: 700;
    color: #737373
}

@media (max-width: 768px) {
    .container {
        width: 90%

    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .container {
        paddingLeft: 240px;
        width: 90%
    }
}