

.container {
    position: relative;
}

.button {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 500px;
    height: 80px;
    border: none;
    font-weight: 700;
    font-size: 40px;
    font-family: Montserrat;
    line-height: 49px;
    color: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(7.45px);
    cursor: pointer;
}

.button:hover {
    border: 3px solid black
}

.spring {
    background-color: #50A342
}

.autumn {
    background-color: #D78915
}

.newTick {
    background-color: red;
    position: absolute;
    top: -15px;
    right: -40px;
    padding: 10px;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 700;
    color: #FFFFFF
}

/*Desktop: 1440px*/
@media screen and (max-width: 1440px) {

}

/*Laptop: 1024px*/
@media screen and (max-width: 1024px) {

}

/*MD: 900px*/

@media screen and (max-width: 900px) {

}

/*Tablet: 768px*/
@media screen and (max-width: 768px) {
    .button {
        border-radius: 15px;
        padding: 10px;
        width: 350px;
        height: 60px;
        font-size: 24px;
    }
    .newTick {
        font-size: 16px;
    }
}

/*Mobile: 425px*/
@media screen and (max-width: 425px) {
    .button {
        border-radius: 15px;
        padding: 10px;
        width: 300px;
        height: 60px;
        font-size: 24px;
    }
    .newTick {
        font-size: 12px;
    }
}

/*Small Mobile: 375px*/

@media screen and (max-width: 375px) {
    .button {
        border-radius: 15px;
        padding: 10px;
        width: 250px;
        height: 60px;
        font-size: 18px;
    }
    .newTick {
        font-size: 12px;
        right: -30px;
    }
}